import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Main from "./components/main/Main";
import styled from "styled-components";
import Auth from "./components/auth/Auth";
import Onboarding from "./components/onboarding/Onboarding";
import Diary from "./components/diary/Diary";
import DiaryWrite from "./components/diary/DiaryWrite";
import Profile from "./components/profile/Profile";
import DiaryDetail from "./components/diary/DiaryDetail";
import ProfileImageCheck from "./components/profileimage/ProfileImageCheck";
import UpdateTheme from "./components/profile/UpdateTheme";
import ProfileImageChange from "./components/profileimage/ProfileImageChange";
import {TokenProvider} from "./context/TokenContext";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #EEF5F6;
    min-width: 320px;
    max-width: 500px;
    padding: env(safe-area-inset-top) 8% env(safe-area-inset-bottom);
`

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <TokenProvider>
        <Wrapper>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Main/>}></Route>
                    <Route path="/auth" element={<Auth/>}></Route>

                    <Route path="/onboarding" element={<Onboarding/>}></Route>

                    <Route path="/diary" element={<Diary/>}></Route>
                    <Route path="/diary/write/:targetDate" element={<DiaryWrite/>}></Route>
                    <Route path="/diary/:diaryId" element={<DiaryDetail/>}></Route>

                    <Route path="/profile" element={<Profile/>}></Route>
                    <Route path="/profile/image/:profileImageId" element={<ProfileImageCheck/>}></Route>
                    <Route path="/profile/theme/edit" element={<UpdateTheme/>}></Route>

                    <Route path="/profile/image/change" element={<ProfileImageChange/>}></Route>
                </Routes>
            </BrowserRouter>
        </Wrapper>
    </TokenProvider>
);

serviceWorkerRegistration.register();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
