import {DotLoader, FadeLoader} from "react-spinners";
import styled from "styled-components";

const Text = styled.span`
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    margin-bottom: 40px;
    font-size: 18px;
    color: #999999;
`

export default function Loading({text}) {
    return (
        <>
            <Text>{text}</Text>
            <FadeLoader color={"#1189B7"}/>
        </>
    )
}