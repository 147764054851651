import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {api, apiWithToken} from "../../api/Api";
import {useToken} from "../../context/TokenContext";

export default function Auth() {

    const navigate = useNavigate()

    const [searchParams] = useSearchParams();

    const {updateToken} = useToken()

    useEffect(() => {
        api.post("/api/v1/auth?platform=kakao", {
            code: searchParams.get('code'),
            state: process.env.REACT_APP_KAKAO_REDIRECT_URL
        }).then(response => {
            updateToken(response.data.data.accessToken)
            api.get("/api/v1/user/image/exists", {
                headers: {
                    "Authorization": "Bearear " + response.data.data.accessToken
                }
            }).then(response2 => {
                if (response2.data.data.result === true) {
                    navigate("/diary")
                } else {
                    navigate("/onboarding")
                }
            }).catch(err => {
                alert("다시 시도해 주세요.")
                navigate("/")
            })
        }).catch(error => {
            alert("다시 시도해 주세요.")
            navigate("/")
        })
    }, []);

    return (
        <></>
    )
}
