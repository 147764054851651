import styled from "styled-components";
import {useEffect, useState} from "react";
import Header from "../base/Header";
import {api} from "../../api/Api";
import {useNavigate} from "react-router-dom";
import Loading from "../base/Loading";
import {useToken} from "../../context/TokenContext";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 100px 0 60px 0;
`

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const TextDescription = styled.span`
    width: 100%;
    font-size: 22px;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    margin-bottom: 15px;
`

const AppearanceInputBox = styled.textarea`
    width: 100%;
    height: 200px;
    background-color: #ffffff;
    border-radius: 8px;
    outline: none;
    border: 2px solid #ccc;
    padding: 10px;
    box-sizing: border-box;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    font-size: 16px;
    line-height: 1.3;

    &::placeholder {
        color: #C7C7C7;
    }
`

const NextButton = styled.button`
    width: 84%;
    height: 50px;
    line-height: 25px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    border: 2px solid #1189B7;
    border-radius: 10px;
    background-color: #1189B7;
    color: #ffffff;
    box-sizing: border-box;
    outline: none;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    position: fixed;
    bottom: 40px;
`

export default function ProfileImageChange({setTitle, setProgressBarWidth, data, onChange, onNext}) {

    const [appearance, setAppearance] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const {accessToken} = useToken()

    const handleSubmitButton = function () {
        api.post("/api/v1/user/image", {
            appearance: data['appearance']
        }, {
            headers: {Authorization: `Bearer ${accessToken}`}
        }).then(res1 => {
            setLoading(false)
            // navigate("/profile/image/" + res1.data.data.id)
        })
    }

    if (loading) {
        <Loading text={"잠시만 기다려 주세요.."}/>
    }
    return (
        <>
            <Header title={"프로필 이미지 재생성"}/>
            <Wrapper>
                <ContentWrapper>
                    <TextDescription>외형</TextDescription>
                    <AppearanceInputBox
                        onChange={(e) => setAppearance(e.target.value)}
                        placeholder={"외형(머리 스타일, 안경, 체형 등..)을 \n입력해 주세요.\n구체적일수록 더 좋은 프로필 이미지가 생성됩니다.\n\n" +
                            "e.g. 검정색 가르마펌, 안경 없음, 보통 체형"}
                    />
                </ContentWrapper>
                <NextButton onClick={handleSubmitButton}>다음</NextButton>
            </Wrapper>
        </>
    )
}