import {useNavigate, useParams} from "react-router-dom";
import Header from "../base/Header";
import {useEffect, useState} from "react";
import styled from "styled-components";
import Loading from "../base/Loading";
import {api} from "../../api/Api";
import {useToken} from "../../context/TokenContext";

const ProfileImage = styled.img`
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 10px;
    box-shadow: 2px 4px 12px rgba(1, 1, 1, 0.03);
    margin-top: 55px;
`

const RefreshButton = styled.button`
    width: 100%;
    height: 50px;
    line-height: 25px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    border: 2px solid #1189B7;
    border-radius: 10px;
    background-color: #ffffff;
    color: #1189B7;
    box-sizing: border-box;
    outline: none;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    margin-bottom: 25px;
    margin-top: 40px;
`

const Button = styled.button`
    width: 100%;
    height: 50px;
    line-height: 25px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    border: 2px solid #1189B7;
    border-radius: 10px;
    background-color: #1189B7;
    color: #ffffff;
    box-sizing: border-box;
    outline: none;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
`

export default function ProfileImageCheck() {
    const {profileImageId} = useParams()
    const navigate = useNavigate()
    const [profileImage, setProfileImage] = useState({})
    const [loading, setLoading] = useState(false)

    const {accessToken} = useToken()

    useEffect(() => {
        api.get("/api/v1/user/image/" + profileImageId, {
            headers: {Authorization: `Bearer ${accessToken}`}
        })
            .then(res => {
                setProfileImage(res.data.data)
            })
    }, []);

    const handleApplyButton = function () {
        api.post("/api/v1/user/image/apply/" + profileImageId, null, {
            headers: {Authorization: `Bearer ${accessToken}`}
        })
            .then(res => {
                navigate("/diary")
            })
    }

    const handleRefreshButton = function () {
        setLoading(true)
        api.post("/api/v1/user/image", {
            appearance: profileImage.appearance
        }, {
            headers: {Authorization: `Bearer ${accessToken}`}
        }).then(res1 => {
            setLoading(false)
            navigate("/profile/image/" + res1.data.data.id)
        })
    }

    if (loading) {
        return (
            <Loading text={"이미지 생성중입니다.."}/>
        )
    } else {
        return (
            <>
                <Header title={"프로필 이미지 확인"}/>
                <ProfileImage src={"https://d29vah720ra688.cloudfront.net/" + profileImage.imageUrl}></ProfileImage>
                <RefreshButton onClick={handleRefreshButton}>새로 만들기</RefreshButton>
                <Button onClick={handleApplyButton}>적용하기</Button>
            </>
        )
    }
}