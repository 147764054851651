import Header from "../base/Header";
import styled from "styled-components";
import {GrFormNext} from "react-icons/gr";
import {useEffect, useState} from "react";
import {api} from "../../api/Api";
import {useNavigate} from "react-router-dom";
import {useToken} from "../../context/TokenContext";

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    padding: 80px 0 60px 0;
    display: flex;
    align-items: start;
    box-sizing: border-box;
    flex-direction: column;
`

const ProfileWrapper = styled.div`
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 40px;
`

const ProfileImage = styled.img`
    width: 70px;
    height: 70px;
    background-color: #888888;
    border-radius: 100%;
    border: none;
    box-shadow: 2px 4px 12px rgba(1, 1, 1, 0, 15);
`

const ProfileInfoWrapper = styled.div`
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const NicknameText = styled.span`
    font-size: 20px;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    margin-bottom: 10px;
`

const DescriptionText = styled.span`
    font-size: 16px;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    color: #999999;
`

const ButtonsWrapper = styled.div`
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 20px;
`

const DivideLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: #f7f7f7;
    margin: 10px 0;
`

const LinkButton = styled.button`
    width: 100%;
    height: 30px;
    line-height: 30px;
    background-color: #ffffff;
    border: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const LinkTitle = styled.span`
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    color: #1c1b1a;
    font-size: 16px;
`

const genderMap = {
    "MAN": "남성",
    "WOMAN": "여성"
}

export default function Profile() {
    const navigate = useNavigate()

    const [user, setUser] = useState({})
    const [profileImage, setProfileImage] = useState({})

    const {accessToken} = useToken()

    useEffect(() => {
        const userRequest = api.get("/api/v1/user", {
            headers: {Authorization: `Bearer ${accessToken}`}
        })
        const profileImageRequest = api.get("/api/v1/user/image", {
            headers: {Authorization: `Bearer ${accessToken}`}
        })

        Promise.all([userRequest, profileImageRequest])
            .then((responses) => {
                const [userResponse, profileImageResponse] = responses
                setUser(userResponse.data.data)
                setProfileImage(profileImageResponse.data.data)
            })
    }, []);

    return (
        <>
            <Header title={"마이페이지"}/>
            <Wrapper>
                <ProfileWrapper>
                    <ProfileImage src={"https://d29vah720ra688.cloudfront.net/" + profileImage['imageUrl']}/>
                    <ProfileInfoWrapper>
                        <NicknameText>{user.nickname}</NicknameText>
                        <DescriptionText>{genderMap[user.gender]} · {user.age}세</DescriptionText>
                    </ProfileInfoWrapper>
                </ProfileWrapper>
                <ButtonsWrapper>
                    <DescriptionText>설정</DescriptionText>
                    <DivideLine/>
                    <LinkButton>
                        <LinkTitle>정보 수정</LinkTitle>
                        <GrFormNext size={"20px"} color={"#999999"}/>
                    </LinkButton>
                    <DivideLine/>
                    <LinkButton>
                        <LinkTitle>프로필 이미지 재생성</LinkTitle>
                        <GrFormNext size={"20px"} color={"#999999"}/>
                    </LinkButton>
                    <DivideLine/>
                    <LinkButton onClick={() => navigate("/profile/theme/edit")}>
                        <LinkTitle>이미지 테마 설정</LinkTitle>
                        <GrFormNext size={"20px"} color={"#999999"}/>
                    </LinkButton>
                </ButtonsWrapper>
                <ButtonsWrapper>
                    <DescriptionText>고객지원</DescriptionText>
                    <DivideLine/>
                    <LinkButton>
                        <LinkTitle>공지사항</LinkTitle>
                        <GrFormNext size={"20px"} color={"#999999"}/>
                    </LinkButton>
                    <DivideLine/>
                    <LinkButton>
                        <LinkTitle>이용약관</LinkTitle>
                        <GrFormNext size={"20px"} color={"#999999"}/>
                    </LinkButton>
                    <DivideLine/>
                    <LinkButton>
                        <LinkTitle>문의사항</LinkTitle>
                        <GrFormNext size={"20px"} color={"#999999"}/>
                    </LinkButton>
                </ButtonsWrapper>
            </Wrapper>
        </>
    )
}