import styled, {css, keyframes} from "styled-components";
import {IoClose} from "react-icons/io5";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const ModalWrapper = styled.button`
    position: fixed;
    width: 84%;
    height: 180px;
    box-shadow: 2px 4px 8px rgba(1, 1, 1, 0.03);
    border-radius: 8px;
    background-color: #ffffff;
    bottom: 5%;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    outline: none;
    display: flex;
`

const ModalCloseButton = styled.button`
    width: 24px;
    height: 24px;
    padding: 0;
    background-color: #ffffff;
    border: none;
    position: absolute;
    top: 8px;
    right: 10px;
`

const DiaryImage = styled.img`
    width: 160px;
    height: 160px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 2px 4px 12px rgba(1, 1, 1, 0.1);
    outline: none;
    border: none;
`

const DiaryContentsWrapper = styled.div`
    margin-left: 10px;
    width: calc(100% - 170px);
    aspect-ratio: 1 / 1;
    display: flex;
    flex-direction: column;
`

const TargetDate = styled.span`
    width: 100%;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    font-size: 20px;
    text-align: start;
    color: #1c1b1a;
`

const DiaryContent = styled.div`
    text-align: start;
    width: 100%;
    height: calc(100% - 10px);
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    font-size: 14px;
    margin-top: 10px;
    color: #3F3F3F;
    display: -webkit-box;       
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;  
    overflow: hidden;       
    text-overflow: ellipsis;
    line-height: 1.3;
`

export default function DiaryPreviewModal({previewModalOpen, setPreviewModalOpen, diaryId, diary}) {

    const navigate = useNavigate()

    const handleModalCloseButton = function (e) {
        setPreviewModalOpen(false)
        e.stopPropagation()
    }

    const toKoreanDate = function (date) {
        const splitted = date.split("-")
        return splitted[1] + "월 " + splitted[2] + "일"
    }

    return (
        <ModalWrapper onClick={() => navigate("/diary/" + diaryId)} isOpen={previewModalOpen}>
            <DiaryImage src={"https://d29vah720ra688.cloudfront.net/" + diary.diaryImageUrl}/>
            <DiaryContentsWrapper>
                <TargetDate>
                    {toKoreanDate(diary.targetDate)}
                </TargetDate>
                <DiaryContent>
                    {diary.content}
                </DiaryContent>
            </DiaryContentsWrapper>
            <ModalCloseButton onClick={handleModalCloseButton}>
                <IoClose size={"24px"} color={"#999"}/>
            </ModalCloseButton>
        </ModalWrapper>
    )
}