import {useNavigate, useParams} from "react-router-dom";
import Header from "../base/Header";
import styled from "styled-components";
import {useState} from "react";
import Loading from "../base/Loading";
import {api} from "../../api/Api";
import DiaryCommentModal from "./DiaryCommentModal";
import {useToken} from "../../context/TokenContext";

const dayMap = {
    "0": "일",
    "1": "월",
    "2": "화",
    "3": "수",
    "4": "목",
    "5": "금",
    "6": "토",
}

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 100px 0 0 0;
    flex-direction: column;
`

const TitleText = styled.span`
    font-size: 26px;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    width: 100%;
`

const DiaryInputBox = styled.textarea`
    width: 100%;
    aspect-ratio: 1/1;
    background-color: #ffffff;
    margin-top: 25px;
    border-radius: 8px;
    outline: none;
    border: none;
    padding: 20px;
    box-sizing: border-box;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    font-size: 16px;
    box-shadow: 2px 4px 16px rgba(1, 1, 1, 0.03);
`

const WriteButton = styled.button`
    width: 100%;
    height: 50px;
    line-height: 25px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    border: 2px solid #1189B7;
    border-radius: 10px;
    background-color: #1189B7;
    color: #ffffff;
    box-sizing: border-box;
    outline: none;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    margin-top: 30px;
`

export default function DiaryWrite() {
    const {targetDate} = useParams()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [content, setContent] = useState("")
    const [comment, setComment] = useState("")

    const {accessToken} = useToken()

    const date = new Date(targetDate + "T00:00:00Z")
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const dayOfWeek = dayMap[date.getDay()]

    const handleSubmitButton = function () {
        const targetDate = new Date(year, month - 1, day)
        const formattedDate = targetDate.toLocaleDateString("ko-KR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        }).replace(/. /g, "-").replace(".", "");

        api.post("/api/v1/diary", {
            targetDate: formattedDate,
            content: content,
            scope: "PUBLIC"
        }, {
            headers: {Authorization: `Bearer ${accessToken}`}
        }).then(res1 => {
            setLoading(true)
            api.post("/api/v1/diary/" + res1.data.data.id + "/analyze", null, {
                headers: {Authorization: `Bearer ${accessToken}`}
            })
                .then(res => {
                    setComment(res.data.data.comment)
                    setIsModalOpen(true)
                    api.post("/api/v1/diary/" + res1.data.data.id + "/image", {
                        prompt: res.data.data.prompt
                    }, {
                        headers: {Authorization: `Bearer ${accessToken}`}
                    }).then(res2 => {
                        api.post("/api/v1/diary/" + res1.data.data.id + "/image/" + res2.data.data.id, null, {
                            headers: {Authorization: `Bearer ${accessToken}`}
                        })
                            .then(res3 => {
                                setLoading(false)
                                navigate("/diary/" + res1.data.data.id)
                            })
                    })
                })
        }).catch(err => {
            setLoading(false)
            alert("이미 작성된 일기가 있습니다.")
            navigate("/diary")
        })
    }

    if (loading) {
        return (
            <>
                <Loading text={"잠시만 기다려 주세요.."}/>
                {
                    isModalOpen && <DiaryCommentModal setModelOpen={setIsModalOpen} comment={comment}/>
                }
            </>
        )
    } else {
        return (
            <>
                {
                    isModalOpen && <DiaryCommentModal
                        setModelOpen={setIsModalOpen}
                        comment={comment}/>
                }
                <Header title={"일기 작성"}/>
                <Wrapper>
                    <div>
                        <TitleText>{year}년 {month}월 {day}일 {dayOfWeek}요일</TitleText>
                        <DiaryInputBox placeholder={"일기를 작성해 주세요"}
                                       onChange={(e) => setContent(e.target.value)}/>
                    </div>
                    <WriteButton onClick={handleSubmitButton}>작성하기</WriteButton>
                </Wrapper>
            </>
        )
    }
}