import Header from "../base/Header";
import styled from "styled-components";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useToken} from "../../context/TokenContext";
import {api} from "../../api/Api";

const ContentWrapper = styled.div`
    width: 100%;
    height: 100vh;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
`

const DateTitleText = styled.span`
    font-size: 14px;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    color: #999999;
`

const HomeButton = styled.button`
    font-size: 14px;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    color: #999999;
    padding: 0;
    margin-top: 20px;
    outline: none;
    border: none;
    background-color: #EEF5F6;
    width: 70px;
    text-align: start;
`

const DescriptionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
`

const ButtonText = styled.button`
    border: none;
    background-color: #EEF5F6;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    font-size: 14px;
    width: 30px;
    padding: 0;
    color: #999999;
    text-decoration: underline;
`

const DiaryImage = styled.img`
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: #282c34;
    border-radius: 10px;
`

const DiaryContent = styled.div`
    width: 100%;
    font-size: 16px;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    margin-top: 15px;
    line-height: 1.3;
`

export default function DiaryDetail() {
    const {diaryId} = useParams()
    const navigate = useNavigate()

    const [diary, setDiary] = useState({})

    const {accessToken} = useToken()

    useEffect(() => {
        api.get("/api/v1/diary/" + diaryId, {
            headers: {Authorization: `Bearer ${accessToken}`}
        }).then(res => {
            setDiary(res.data.data)
        }).catch(err => {
            alert("작성된 일기가 없습니다.")
            navigate("/diary")
        })
    }, []);

    const toKoreanDate = function (target) {
        console.log(target)
        const splitted = target.split("-")
        return splitted[0] + "년 " + splitted[1] + "월 " + splitted[2] + "일"
    }

    return (
        <>
            <Header title={"일기"}/>
            <ContentWrapper>
                <DiaryImage src={"https://d29vah720ra688.cloudfront.net/" + diary.diaryImageUrl}/>
                <DescriptionWrapper>
                    <DateTitleText>{diary.targetDate}</DateTitleText>
                    <div>
                        <ButtonText onClick={() => alert("asd")}>삭제</ButtonText>
                    </div>
                </DescriptionWrapper>
                <DiaryContent>{diary.content}</DiaryContent>
                <HomeButton onClick={() => navigate("/diary")}>홈으로 가기</HomeButton>
            </ContentWrapper>
        </>
    )
}