import styled from "styled-components";
import {IoClose} from "react-icons/io5";
import {useNavigate} from "react-router-dom";

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); // 배경을 어둡게
    z-index: 0;
`

const ModalWrapper = styled.div`
    position: fixed;
    width: 84%;
    height: 280px;
    box-shadow: 2px 4px 16px rgba(1, 1, 1, 0.03);
    border-radius: 8px;
    background-color: #ffffff;
    //bottom: 5%;
    padding: 20px;
    box-sizing: border-box;
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ModalCloseButton = styled.button`
    width: 24px;
    height: 24px;
    padding: 0;
    background-color: #ffffff;
    border: none;
    position: absolute;
    top: 18px;
    right: 20px;
`

const DescriptionText = styled.span`
    width: 100%;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    font-size: 24px;
    margin: 5px 0;

`

const DiaryCreateButton = styled.button`
    width: 100%;
    height: 50px;
    background-color: #1189B7;
    border: none;
    color: #ffffff;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    font-weight: 700;
    font-size: 20px;
    margin-top: 25px;
    padding: 0;
`
const HideModalButton = styled.button`
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    color: #999999;
    text-decoration: underline;
    width: 130px;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    margin-top: 15px;
    padding: 0;
    background-color: #ffffff;
    border: none;
`

export default function DiaryWriteSuggestModal({setModalOpen}) {
    const navigate = useNavigate()
    const date = new Date()

    const handleWriteButton = function () {
        const formattedDate = date.toLocaleDateString("ko-KR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        }).replace(/. /g, "-").replace(".", "");
        navigate("/diary/write/" + formattedDate)
    }

    const handleModalCloseButton = function () {
        setModalOpen(false)
    }

    const handleHideButton = function () {
        localStorage.setItem('hideAdUntil', date.toISOString().split('T')[0]);
        setModalOpen(false)
    }

    return (
        <>
            <Backdrop/>
            <ModalWrapper>
                <ModalCloseButton onClick={handleModalCloseButton}>
                    <IoClose size={"24px"} color={"#999"}/>
                </ModalCloseButton>
                <DescriptionText>
                    잠깐!
                </DescriptionText>
                <DescriptionText>
                    아직 오늘의 일기를
                </DescriptionText>
                <DescriptionText>
                    작성하지 않으셨어요!
                </DescriptionText>
                <DiaryCreateButton onClick={handleWriteButton}>
                    작성하기
                </DiaryCreateButton>
                <HideModalButton onClick={handleHideButton}>
                    오늘 하루 보지 않기
                </HideModalButton>
            </ModalWrapper>
        </>
    )
}