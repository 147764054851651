import {createContext, useContext, useState} from "react";

export const TokenContext = createContext(null)

export const TokenProvider = ({children}) => {
    const [accessToken, setAccessToken] = useState('');

    const updateToken = (token) => {
        setAccessToken(token);
    };

    return (
        <TokenContext.Provider value={{accessToken, updateToken}}>
            {children}
        </TokenContext.Provider>
    );
}

export const useToken = () => useContext(TokenContext)
