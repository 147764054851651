import styled from "styled-components";
import kakaoButton from "../../images/kakao_login_medium_wide.png"
import mainIcon from "../../icons/main-icon 3.png"
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {deleteAccessToken, isLoggedIn} from "../../auth/Token";

const Wrapper = styled.div`
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
`

const BigRound = styled.div`
    width: 250px;
    height: 250px;
    border-radius: 100%;
    background-color: #122266;
    position: absolute;
    top: -130px;
    right: -20px;
`

const SmallRound = styled.div`
    width: 130px;
    height: 140px;
    border-radius: 100%;
    background-color: #1189B7;
    position: absolute;
    top: 50px;
    right: -70px;
`

const LoginButton = styled.button`
    border: none;
    outline: none;
    background-color: #EEF5F6;
    width: 100%;
    border-radius: 3px;
    padding: 0;
    margin: 0;
`

const KakaoButton = styled.img`
    width: 100%;
    padding: 0;
    margin: 0;
`

const MainIcon = styled.img`
    width: 80px;
    padding: 0;
    margin: 70px 0 5px;
`

const TitleText = styled.span`
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    font-size: 44px;
    margin-bottom: 170px;
`

const PersonalInfoText = styled.span`
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    color: #999999;
    text-decoration: underline;
    margin-top: 12px;
`

const ContentWrapper = styled.div`
    width: 84%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: absolute;
`

export default function Main() {

    const navigate = useNavigate()

    useEffect(() => {
        // deleteAccessToken()
    }, []);

    const kakaoLoginButtonHandler = (e) => {
        e.preventDefault()
        console.log(process.env.REACT_APP_KAKAO_URL)
        window.location = process.env.REACT_APP_KAKAO_URL
    }

    return (
        <Wrapper>
            <BigRound/>
            <SmallRound/>
            <ContentWrapper>
                <MainIcon src={mainIcon}/>
                <TitleText>오늘을, 그리다</TitleText>
                <LoginButton onClick={kakaoLoginButtonHandler}>
                    <KakaoButton src={kakaoButton}/>
                </LoginButton>
                <PersonalInfoText>개인정보 처리 방침</PersonalInfoText>
            </ContentWrapper>
        </Wrapper>
    )
}