import {IoChevronBackSharp} from "react-icons/io5";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";

const HeaderWrap = styled.div`
    width: 100vw;
    height: 70px;
    background-color: #EEF5F6;
    position: absolute;
    top: 5px;
    padding: 0 4% 0 4%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    z-index: 0;
`

const BackButton = styled.button`
    background-color: #EEF5F6;
    outline: none;
    border: none;
    width: 30px;
    padding: 0;
    margin-left: 8px;
`

const TitleText = styled.span`
    font-size: 20px;
    font-weight: 400;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    padding-bottom: 4px;
    margin-left: 10px;
`

export default function Header({title}) {

    const navigate = useNavigate();

    const handlePrevButton = function (e) {
        navigate(-1)
    }

    return (
        <HeaderWrap>
            <BackButton onClick={handlePrevButton}>
                <IoChevronBackSharp size={"24px"} color={"#1c1b1a"}/>
            </BackButton>
            <TitleText>{title}</TitleText>
        </HeaderWrap>
    )
}