import styled from "styled-components";
import {Outlet, useNavigate} from "react-router-dom";
import {IoChevronBackSharp} from "react-icons/io5";
import {useState} from "react";
import OnboardingPersonal from "./OnboardingPersonal";
import OnboardingNickname from "./OnboardingNickname";
import OnboardingAppearance from "./OnboardingAppearance";
import OnboardingTheme from "./OnboardingTheme";

const ProgressBar = styled.div`
    width: 100vw;
    height: 5px;
    position: absolute;
    top: 0;
`

const ProgressBarStatus = styled.div`
    width: ${(props) => props.width};
    height: 5px;
    background-color: #1189B7;
    position: absolute;
    top: 0;
    border-bottom-right-radius: 1px;
    border-top-right-radius: 1px;
`

const HeaderWrap = styled.div`
    width: 100vw;
    height: 70px;
    background-color: #EEF5F6;
    position: absolute;
    top: 5px;
    padding: 0 4% 0 4%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`

const BackButton = styled.button`
    background-color: #EEF5F6;
    outline: none;
    border: none;
    width: 30px;
    padding: 0;
    margin-left: 8px;
`

const TitleText = styled.span`
    font-size: 20px;
    font-weight: 400;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    padding-bottom: 4px;
    margin-left: 10px;
`

export default function Onboarding() {
    const navigate = useNavigate()

    const [title, setTitle] = useState("")
    const [progressBarWidth, setProgressBarWidth] = useState(0)

    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        nickname: '',
        gender: '',
        age: 0,
        theme: '',
        appearance: ''
    });

    const handleNextStep = () => {
        switch (step) {
            case 1:
                if (formData['nickname'] === '') {
                    alert("닉네임을 입력해 주세요.")
                    return
                }
                break
            case 2:
                if (formData['gender'] === '') {
                    alert("성별을 입력해 주세요.")
                    return
                }
                if (formData['age'] === 0) {
                    alert("나이를 입력해 주세요.")
                    return
                }
                break
            case 3:
                break
            case 4:
                break
        }
        setStep((prev) => prev + 1);
    };

    const handlePrevStep = () => {
        if (step === 1) {
            navigate("/")
        } else {
            setStep((prev) => prev - 1);
        }
    };

    const handleChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    return (
        <>
            <ProgressBar>
                <ProgressBarStatus width={`${progressBarWidth}%`}/>
            </ProgressBar>
            <HeaderWrap>
                <BackButton onClick={handlePrevStep}>
                    <IoChevronBackSharp size={"24px"}/>
                </BackButton>
                <TitleText>{title}</TitleText>
            </HeaderWrap>
            {step === 1 && (
                <OnboardingNickname
                    setTitle={setTitle}
                    setProgressBarWidth={setProgressBarWidth}
                    data={formData}
                    onChange={handleChange}
                    onNext={handleNextStep}
                />
            )}
            {step === 2 && (
                <OnboardingPersonal
                    setTitle={setTitle}
                    setProgressBarWidth={setProgressBarWidth}
                    data={formData}
                    onChange={handleChange}
                    setStep={setStep}
                />
            )}
            {step === 3 && (
                <OnboardingAppearance
                    setTitle={setTitle}
                    setProgressBarWidth={setProgressBarWidth}
                    data={formData}
                    onChange={handleChange}
                    onNext={handleNextStep}
                    onBack={handlePrevStep}
                />
            )}
            {step === 4 && (
                <OnboardingTheme
                    setTitle={setTitle}
                    setProgressBarWidth={setProgressBarWidth}
                    data={formData}
                    onChange={handleChange}
                    onBack={handlePrevStep}
                />
            )}
        </>
    )
}