import styled from "styled-components";
import {IoClose} from "react-icons/io5";
import {useNavigate} from "react-router-dom";

const ModalWrapper = styled.div`
    position: fixed;
    width: 84%;
    height: 140px;
    box-shadow: 2px 4px 16px rgba(1, 1, 1, 0.03);
    border-radius: 8px;
    background-color: #ffffff;
    bottom: 5%;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`

const ModalCloseButton = styled.button`
    width: 24px;
    height: 24px;
    padding: 0;
    background-color: #ffffff;
    border: none;
    position: absolute;
    top: 8px;
    right: 10px;
`

const DescriptionText = styled.span`
    width: 100%;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    font-size: 20px;
`

const DateText = styled.span`
    width: 100%;
    margin-bottom: 10px;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    font-size: 14px;
    color: #999999;
`

const DiaryCreateButton = styled.button`
    width: 100%;
    height: 50px;
    background-color: #1189B7;
    border: none;
    color: #ffffff;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    font-weight: 700;
`

export default function DiaryCreateButtonModal({modalOpen, setModalOpen, selectedDate, setSelectedDate}) {
    const navigate = useNavigate()

    const handleModalCloseButton = function (e) {
        setSelectedDate((prevData) => ({
            ...prevData,
            'year': 0,
            'month': 0,
            'day': 0,
        }));
        setModalOpen(false)
        e.stopPropagation()
    }

    const handleWriteButton = function ({setModalOpen}) {
        const date = new Date(selectedDate['year'], selectedDate['month'] - 1, selectedDate['day'])
        const formattedDate = date.toLocaleDateString("ko-KR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        }).replace(/. /g, "-").replace(".", "");
        navigate("/diary/write/" + formattedDate)
    }

    return (
        <ModalWrapper>
            <ModalCloseButton onClick={handleModalCloseButton}>
                <IoClose size={"24px"} color={"#999"}/>
            </ModalCloseButton>
            <DescriptionText>
                작성된 일기가 없습니다.
            </DescriptionText>
            <DateText>
                {selectedDate['year']}년 {selectedDate['month']}월 {selectedDate['day']}일
            </DateText>
            <DiaryCreateButton onClick={handleWriteButton}>
                일기 작성하기
            </DiaryCreateButton>
        </ModalWrapper>
    )
}