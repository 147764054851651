import styled from "styled-components";
import {useEffect, useState} from "react";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 100px 0 60px 0;
`

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const TextDescription = styled.span`
    width: 100%;
    font-size: 22px;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    margin-bottom: 15px;
`

const GenderSelectWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
`

const RadioButtonLabel = styled.label`
    width: 48%;
    height: 50px;
    line-height: 25px;
    text-align: center;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: 2px solid ${({selected}) => (selected ? '#1189B7' : '#ccc')};
    border-radius: 10px;
    background-color: ${({selected}) => (selected ? '#1189B7' : '#fff')};
    color: ${({selected}) => (selected ? '#fff' : '#1c1b1a')};
    transition: all 0.2s;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    box-sizing: border-box;

    &:hover {
        border-color: #1189B7;
    }
`

const HiddenRadioButton = styled.input.attrs({type: 'radio'})`
    display: none;
`

const InputBox = styled.input`
    width: 100%;
    height: 45px;
    line-height: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: 2px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    color: #1c1b1a;
    box-sizing: border-box;
    outline: none;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
`

const NextButton = styled.button`
    width: 84%;
    height: 50px;
    line-height: 25px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    border: 2px solid #1189B7;
    border-radius: 10px;
    background-color: #1189B7;
    color: #ffffff;
    box-sizing: border-box;
    outline: none;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    position: fixed;
    bottom: 40px;
`


export default function OnboardingNickname({setTitle, setProgressBarWidth, data, onChange, onNext}) {

    useEffect(() => {
        setTitle("기본 정보 입력")
        setProgressBarWidth(25)
    }, []);

    return (
        <Wrapper>
            <ContentWrapper>
                <TextDescription>닉네임</TextDescription>
                <InputBox
                    type={"text"}
                    value={data['nickname']}
                    onChange={(e) => onChange('nickname', e.target.value)}
                ></InputBox>
            </ContentWrapper>
            <NextButton onClick={onNext}>다음</NextButton>
        </Wrapper>
    )
}