import styled from "styled-components";
import {useEffect, useState} from "react";
import {api} from "../../api/Api";
import Loading from "../base/Loading";
import {useNavigate} from "react-router-dom";
import {useToken} from "../../context/TokenContext";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    height: 100%;
    padding: 100px 0 60px 0;
    overflow: scroll;
`

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const TextDescription = styled.span`
    width: 100%;
    font-size: 22px;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    margin-bottom: 15px;
`

const ThemeSelectWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-wrap: wrap;
`

const RadioButtonLabel = styled.label`
    width: 48%;
    aspect-ratio: 1 / 1;
    line-height: 20px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    padding: 2px;
    border: 2px solid ${({selected}) => (selected ? '#1189B7' : '#ccc')};
    border-radius: 10px;
    color: ${({selected}) => (selected ? '#fff' : '#1c1b1a')};
    transition: all 0.2s;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    box-sizing: border-box;
    margin-bottom: 15px;

    &:hover {
        border-color: #1189B7;
    }
`

const SampleImage = styled.img`
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 10px;
`

const HiddenRadioButton = styled.input.attrs({type: 'radio'})`
    display: none;
`

const NextButton = styled.button`
    width: 84%;
    height: 50px;
    line-height: 25px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    border: 2px solid #1189B7;
    border-radius: 10px;
    background-color: #1189B7;
    color: #ffffff;
    box-sizing: border-box;
    outline: none;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    position: fixed;
    bottom: 40px;
`

export default function OnboardingTheme({setTitle, setProgressBarWidth, data, onChange, onNext}) {
    const [selectedTheme, setSelectedTheme] = useState('');
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const {accessToken} = useToken()

    const handleThemeChange = (event) => {
        setSelectedTheme(event.target.value);
        onChange('theme', event.target.value)
    };

    useEffect(() => {
        setTitle("기본 정보 입력")
        setProgressBarWidth(100)
    }, []);

    const handleNextButton = function () {
        api.post("/api/v1/user/theme", {
            theme: data['theme']
        }, {
            headers: {Authorization: `Bearer ${accessToken}`}
        })
            .then((response) => {
                setLoading(true)
                api.post("/api/v1/user/image", {
                    appearance: data['appearance']
                }, {
                    headers: {Authorization: `Bearer ${accessToken}`}
                }).then(res1 => {
                    setLoading(false)
                    navigate("/profile/image/" + res1.data.data.id)
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    if (loading) {
        return (
            <Loading text={"이미지 생성중입니다.."}/>
        )
    } else {
        return (
            <Wrapper>
                <ContentWrapper>
                    <TextDescription>선호하는 테마를 골라주세요</TextDescription>
                    <ThemeSelectWrapper>
                        <HiddenRadioButton
                            id="일러스트 스타일"
                            name="theme"
                            value="일러스트 스타일"
                            checked={selectedTheme === '일러스트 스타일'}
                            onChange={handleThemeChange}
                        />
                        <RadioButtonLabel
                            htmlFor="일러스트 스타일"
                            selected={selectedTheme === '일러스트 스타일'}
                        >
                            <SampleImage
                                src={"https://d29vah720ra688.cloudfront.net/images/illust-style.png"}
                            ></SampleImage>
                        </RadioButtonLabel>

                        <HiddenRadioButton
                            id="수채화 스타일"
                            name="theme"
                            value="수채화 스타일"
                            checked={selectedTheme === '수채화 스타일'}
                            onChange={handleThemeChange}
                        />
                        <RadioButtonLabel
                            htmlFor="수채화 스타일"
                            selected={selectedTheme === '수채화 스타일'}
                        >
                            <SampleImage
                                src={"https://d29vah720ra688.cloudfront.net/images/painting-style.png"}
                            ></SampleImage>
                        </RadioButtonLabel>

                        <HiddenRadioButton
                            id="만화 스타일"
                            name="theme"
                            value="만화 스타일"
                            checked={selectedTheme === '만화 스타일'}
                            onChange={handleThemeChange}
                        />
                        <RadioButtonLabel
                            htmlFor="만화 스타일"
                            selected={selectedTheme === '만화 스타일'}
                        >
                            <SampleImage
                                src={"https://d29vah720ra688.cloudfront.net/images/cartoon-style.png"}
                            ></SampleImage>
                        </RadioButtonLabel>

                        <HiddenRadioButton
                            id="손그림 스타일"
                            name="theme"
                            value="손그림 스타일"
                            checked={selectedTheme === '손그림 스타일'}
                            onChange={handleThemeChange}
                        />
                        <RadioButtonLabel
                            htmlFor="손그림 스타일"
                            selected={selectedTheme === '손그림 스타일'}
                        >
                            <SampleImage
                                src={"https://d29vah720ra688.cloudfront.net/images/hand-drawing-style.png"}
                            ></SampleImage>

                        </RadioButtonLabel>
                    </ThemeSelectWrapper>
                </ContentWrapper>
                <NextButton onClick={handleNextButton}>완료</NextButton>
            </Wrapper>
        )
    }
}