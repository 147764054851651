import styled from "styled-components";

const Wrapper = styled.div`
    width: 100%;
    height: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
`

const RealElement = styled.button`
    width: 12%;
    aspect-ratio: 1 / 1;
    border: 1px solid #EEF5F6;
    box-shadow: 2px 4px 12px rgba(1, 1, 1, 0.03);
    border-radius: 5px;
    box-sizing: border-box;
    margin: 2px;
    outline: none;
    background-color: #EEF5F6;
    padding: 0;
    display: flex;
    background-image: url(${props => props.imageUrl});
    background-size: cover;
`

const DisplayDate = styled.span`
    width: 13px;
    height: 13px;
    font-size: 10px;
    color: #999;
    margin: 2px;
    border-radius: 100%;
    text-align: center;
`

const TodayDisplayDate = styled.span`
    width: 13px;
    height: 13px;
    font-size: 10px;
    color: #ffffff;
    background-color: #1189B7;
    border-radius: 100%;
    margin: 2px;
    text-align: center;
`

const SelectedElement = styled.div`
    width: 12%;
    aspect-ratio: 1 / 1;
    border: 1px solid #1189B7;
    box-shadow: 2px 4px 12px rgba(1, 1, 1, 0.03);
    border-radius: 5px;
    box-sizing: border-box;
    margin: 2px;
    outline: none;
    //border: none;
    background-color: #EEF5F6;
    padding: 0;
    display: flex;
    background-image: url(${props => props.imageUrl});
    background-size: cover;
`

const FakeElement = styled.div`
    width: 13%;
    aspect-ratio: 1 / 1;
    border: 1px solid #EEF5F6;
    box-sizing: border-box;
    margin: 2px;
    color: #EEF5F6;
`

export default function DiaryCalendar({
                                          year,
                                          month,
                                          day,
                                          setPreviewModalOpen,
                                          setCreateModalOpen,
                                          diaryId,
                                          setDiaryId,
                                          selectedDate,
                                          setSelectedDate,
                                          diaries
                                      }) {

    const endDayOfMonth = new Date(year, month, 0).getDate()
    const dates = []
    for (let i = 0; i < endDayOfMonth; i++) {
        dates.push(i + 1)
    }

    for (let i = 1; i < endDayOfMonth + 1; i++) {
        let target = year + "-" + month + "-" + i;
        if (month < 10) {
            target = year + "-0" + month + "-" + i
        }
        if (i < 10) {
            target = year + "-0" + month + "-0" + i
        }
    }

    const startDayOfMonth = new Date(year + "-" + month).getDay()
    const fakeStartDates = [];
    for (let i = 0; i < startDayOfMonth; i++) {
        fakeStartDates.push(i + 1)
    }

    const lastDayOfMonth = new Date(year, month, 0)
    const lastDayOfWeek = lastDayOfMonth.getDay()
    const lastWeekDaysCount = 6 - lastDayOfWeek
    const fakeLastDates = []
    for (let i = 0; i < lastWeekDaysCount; i++) {
        fakeLastDates.push(i + 1)
    }

    const isToday = (d) => {
        const today = new Date()
        return today.getFullYear() === year && today.getMonth() + 1 === month && today.getDate() === d
    }

    const isTargetDate = (d) => {
        return selectedDate['year'] === year && selectedDate['month'] === month && selectedDate['day'] === d
    }

    const handleModalOpenButton = function (e) {
        if (e.target.value !== undefined) {
            if (new Date() > new Date(year, month - 1, parseInt(e.target.value))) {
                setSelectedDate((prevData) => ({
                    ...prevData, 'year': year, 'month': month, 'day': parseInt(e.target.value),
                }));

                try {
                    const diary = diaries.diaries[e.target.value]

                    if (diary === undefined) {
                        setPreviewModalOpen(false)
                        setCreateModalOpen(false)
                        setCreateModalOpen(true)
                    } else {
                        setDiaryId(diary.diaryId)
                        setCreateModalOpen(false)
                        setPreviewModalOpen(false)
                        setPreviewModalOpen(true)
                    }
                } catch (e) {
                    console.log("c")
                    setPreviewModalOpen(false)
                    setCreateModalOpen(false)
                    setCreateModalOpen(true)
                }
            } else {
                setPreviewModalOpen(false)
                setCreateModalOpen(false)
                setDiaryId(-1)
                setSelectedDate((prevData) => ({
                    ...prevData, 'year': 0, 'month': 0, 'day': 0,
                }));
            }
        }
    }

    const getDiaryImage = function (d) {
        try {
            return diaries.diaries[d].diaryImageUrl
        } catch (e) {
            return ""
        }
    }

    return (<Wrapper>
        {fakeStartDates.map(d => {
            return (<FakeElement key={d}></FakeElement>)
        })}
        {dates.map(d => {
            if (isToday(d) && isTargetDate(d)) {
                return (<SelectedElement onClick={handleModalOpenButton} value={d}
                                         imageUrl={"https://d29vah720ra688.cloudfront.net/" + getDiaryImage(d)}>
                    <TodayDisplayDate>{d}</TodayDisplayDate>
                </SelectedElement>)
            }
            if (isToday(d)) {
                return (<RealElement onClick={handleModalOpenButton} value={d}
                                     imageUrl={"https://d29vah720ra688.cloudfront.net/" + getDiaryImage(d)}>
                    <TodayDisplayDate>{d}</TodayDisplayDate>
                </RealElement>)
            }
            if (isTargetDate(d)) {
                return (<SelectedElement onClick={handleModalOpenButton} value={d}
                                         imageUrl={"https://d29vah720ra688.cloudfront.net/" + getDiaryImage(d)}>
                    <DisplayDate>{d}</DisplayDate>
                </SelectedElement>)
            }
            return (<RealElement
                onClick={handleModalOpenButton}
                value={d}
                imageUrl={"https://d29vah720ra688.cloudfront.net/" + getDiaryImage(d)}
            >
                <DisplayDate>{d}</DisplayDate>
            </RealElement>)
        })}
        {fakeLastDates.map(d => {
            return (<FakeElement key={d}></FakeElement>)
        })}
    </Wrapper>)
}