import styled from "styled-components";
import DiaryCalendar from "./DiaryCalendar";
import {useEffect, useState} from "react";
import DiaryPreviewModal from "./DiaryPreviewModal";
import DiaryCreateButtonModal from "./DiaryCreateButtonModal";
import {GrFormNext, GrFormPrevious} from "react-icons/gr";
import {useNavigate} from "react-router-dom";
import mainLogo from "../../icons/main-icon 3.png"
import DiaryWriteSuggestModal from "./DiaryWriteSuggestModal";
import {api} from "../../api/Api";
import {useToken} from "../../context/TokenContext";


const BigRound = styled.div`
    width: 320px;
    height: 320px;
    border-radius: 100%;
    background-color: #122266;
    position: absolute;
    bottom: -190px;
    left: -20px;
`

const SmallRound = styled.div`
    width: 180px;
    height: 180px;
    border-radius: 100%;
    background-color: #1189B7;
    position: absolute;
    bottom: -90px;
    right: -40px;
`

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    padding-top: 90px;
    flex-direction: column;
`

const MainHeaderWrap = styled.div`
    width: 100vw;
    height: 75px;
    background-color: #EEF5F6;
    position: absolute;
    top: 0;
    padding: 0 4% 0 4%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const MainLeftElement = styled.div`
    display: flex;
    align-items: center;
`

const MainBackButton = styled.button`
    background-color: #EEF5F6;
    outline: none;
    border: none;
    width: 30px;
    padding: 0;
    margin-left: 8px;
`

const MainTitleText = styled.span`
    font-size: 20px;
    font-weight: 400;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    padding-bottom: 3px;
    margin-left: 10px;
`

const MainUserButton = styled.button`
    background-color: #EEF5F6;
    outline: none;
    border: none;
    width: 30px;
    padding: 0;
    margin-right: 8px;
`

const TitleWrapper = styled.div`
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: end;
`

const ButtonsWrapper = styled.div`
    display: flex;
`

const MonthChangeButton = styled.button`
    padding: 0;
    margin-left: 8px;
    border: none;
    background-color: #EEF5F6;
`

const DateTitleText = styled.span`
    font-size: 24px;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    color: #1a1b1c;
`

const Logo = styled.img`
    width: 28px;
`

const ProfileImage = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 100%;
    box-shadow: 2px 4px 12px rgba(1, 1, 1, 0.15);
`

export default function Diary() {
    const navigate = useNavigate()

    const [year, setYear] = useState(2024);
    const [month, setMonth] = useState(11);
    const [day, setDay] = useState(1);

    const [selectedDate, setSelectedDate] = useState({
        year: 0,
        month: 0,
        day: 0,
    });

    const [profileImage, setProfileImage] = useState("")
    const [diaries, setDiaries] = useState({})

    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [createButtonModalOpen, setCreateButtonModalOpen] = useState(false);
    const [suggestModalOpen, setSuggestModalOpen] = useState(true);

    const [modalTargetDiaryId, setModalTargetDiaryId] = useState(1)

    const {accessToken} = useToken()

    useEffect(() => {
        const today = new Date()
        const formattedDate = today.toLocaleDateString("ko-KR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        }).replace(/. /g, "-").replace(".", "");

        const [_year, _month, _day] =
            formattedDate.split('-').map(item => item.trim());
        setYear(parseInt(_year))
        setMonth(parseInt(_month))
        setDay(parseInt(_day))

        const profileImageRequest = api.get("/api/v1/user/image", {
            headers: {Authorization: `Bearer ${accessToken}`}
        })
        const diaryRequest = api.get("/api/v1/diary/monthly/" + year + "/" + month, {
            headers: {Authorization: `Bearer ${accessToken}`}
        })

        Promise.all([profileImageRequest, diaryRequest])
            .then((responses) => {
                const [profileImageResponse, diaryResponse] = responses
                setProfileImage(profileImageResponse.data.data.imageUrl)
                setDiaries(diaryResponse.data.data)
            })
            .catch(err => {
                // window.location.reload();
            })

        const storedDate = localStorage.getItem('hideAdUntil');
        if (storedDate === formattedDate) {
            try {
                const value = diaries.diaries[day].diaryImageUrl
            } catch (e) {
                setSuggestModalOpen(false)
            }
        }
    }, []);

    const handleMonthPlusButton = function () {
        if (month === 12) {
            setYear(year + 1)
            setMonth(1)
            api.get("/api/v1/diary/monthly/" + (year + 1) + "/1", {
                headers: {Authorization: `Bearer ${accessToken}`}
            }).then(res => {
                setDiaries(res.data.data)
            })
        } else {
            setMonth(month + 1)
            api.get("/api/v1/diary/monthly/" + year + "/" + (month + 1), {
                headers: {Authorization: `Bearer ${accessToken}`}
            }).then(res => {
                setDiaries(res.data.data)
            })
        }
    }

    const handleMonthMinusButton = function () {
        if (month === 1) {
            setYear(year - 1)
            setMonth(12)
            api.get("/api/v1/diary/monthly/" + (year - 1) + "/12", {
                headers: {Authorization: `Bearer ${accessToken}`}
            }).then(res => {
                setDiaries(res.data.data)
            })
        } else {
            setMonth(month - 1)
            api.get("/api/v1/diary/monthly/" + year + "/" + (month - 1), {
                headers: {Authorization: `Bearer ${accessToken}`}
            }).then(res => {
                setDiaries(res.data.data)
            })
        }
    }

    return (
        <>
            <BigRound/>
            <SmallRound/>
            <MainHeaderWrap>
                <MainLeftElement>
                    <MainBackButton>
                        <Logo src={mainLogo}/>
                    </MainBackButton>
                    <MainTitleText>오늘을, 그리다</MainTitleText>
                </MainLeftElement>
                <MainUserButton onClick={() => navigate("/profile")}>
                    <ProfileImage src={"https://d29vah720ra688.cloudfront.net/" + profileImage}></ProfileImage>
                </MainUserButton>
            </MainHeaderWrap>
            <Wrapper>
                <TitleWrapper>
                    <DateTitleText>{year}년 {month}월</DateTitleText>
                    <ButtonsWrapper>
                        <MonthChangeButton onClick={handleMonthMinusButton}>
                            <GrFormPrevious size={"20px"} color={"#1c1b1a"}/>
                        </MonthChangeButton>
                        <MonthChangeButton onClick={handleMonthPlusButton}>
                            <GrFormNext size={"20px"} color={"#1c1b1a"}/>
                        </MonthChangeButton>
                    </ButtonsWrapper>
                </TitleWrapper>
                <DiaryCalendar
                    year={year}
                    month={month}
                    day={day}
                    setPreviewModalOpen={setPreviewModalOpen}
                    setCreateModalOpen={setCreateButtonModalOpen}
                    diaryId={modalTargetDiaryId}
                    setDiaryId={setModalTargetDiaryId}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    diaries={diaries}
                />
            </Wrapper>
            {
                previewModalOpen && <DiaryPreviewModal
                    previewModalOpen={previewModalOpen}
                    setPreviewModalOpen={setPreviewModalOpen}
                    diaryId={modalTargetDiaryId}
                    diary={diaries.diaries[selectedDate.day]}
                />
            }
            {
                createButtonModalOpen && <DiaryCreateButtonModal
                    modalOpen={previewModalOpen}
                    setModalOpen={setCreateButtonModalOpen}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    diaryId={modalTargetDiaryId}
                />
            }
            {
                suggestModalOpen && <DiaryWriteSuggestModal
                    setModalOpen={setSuggestModalOpen}
                />
            }
        </>
    )
}