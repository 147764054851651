import styled from "styled-components";
import {IoClose} from "react-icons/io5";

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // 배경을 어둡게
    z-index: 1;
`

const ModalWrapper = styled.div`
    position: fixed;
    width: 84%;
    box-shadow: 2px 4px 16px rgba(1, 1, 1, 0.03);
    border-radius: 8px;
    background-color: #ffffff;
    padding: 20px;
    box-sizing: border-box;
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
`

const ModalCloseButton = styled.button`
    width: 24px;
    height: 24px;
    padding: 0;
    background-color: #ffffff;
    border: none;
    position: absolute;
    top: 18px;
    right: 20px;
`

const DescriptionText = styled.span`
    width: 100%;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    font-size: 20px;
    margin: 2px 0;
`

const CommentText = styled.div`
    width: 100%;
    font-family: 'GangwonEdu_OTFBoldA', sans-serif;
    font-size: 18px;
    background-color: #EEF5F6;
    padding: 20px 10px;
    box-sizing: border-box;
    margin-top: 20px;
    border-radius: 6px;
`

export default function DiaryCommentModal({comment, setModelOpen}) {
    return (
        <>
            <Backdrop/>
            <ModalWrapper>
                <ModalCloseButton onClick={() => setModelOpen(false)}>
                    <IoClose size={"24px"} color={"#999"}/>
                </ModalCloseButton>
                <DescriptionText>똑똑!</DescriptionText>
                <DescriptionText>편지가 도착했어요</DescriptionText>
                <CommentText>
                    {comment}
                </CommentText>
            </ModalWrapper>
        </>
    )
}