export function setAccessToken(token) {
    document.cookie = `accessToken=${token}; path=/; max-age=3600000; samesite=strict`;
}

export function getAccessToken() {
    const name = "accessToken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        return cookie.substring(name.length, cookie.length);
    }
}

export function deleteAccessToken() {
    document.cookie = `accessToken=; path=/; max-age=0; samesite=strict`;
}

export function isLoggedIn() {
    return getAccessToken() != null
}
